import { DEFAULT_OPTIONS, SiteOptions } from './default-options';

export type SiteNames = typeof SITE_NAMES[number];

export type SiteConfiguration = {
  /**
   * Site-specific feature flags.
   */
  features: {
    /**
     * Whether to enable full text search in offers on Home page.
     */
    enableFullTextSearch: boolean;
    /**
     * Whether to enable departments filter in offers on Home page.
     */
    enableDepartmentsFilter: boolean;
  };
  /**
   * Site-specific configuration options. See default-options.ts for more details.
   */
  options: SiteOptions;
};

export const SITE_NAMES = ['mba', 'iazh', 'default'] as const;

export const SITES: Record<SiteNames, SiteConfiguration> = {
  /**
   * MBA site configuration.
   */
  mba: {
    features: {
      enableFullTextSearch: false,
      enableDepartmentsFilter: false
    },
    options: {
      ...DEFAULT_OPTIONS,
      OFFERS_LIST: {
        ...DEFAULT_OPTIONS.OFFERS_LIST,
        ALLOWED_COLUMNS: [
          'offer_type_name',
          'institution',
          'title',
          'municipalities_short'
        ],
        ALLOWED_COLUMNS_MOBILE: ['title', 'institution', 'place'],
        COL_WIDTHS: ['col-3', 'col-3', 'col-4', 'col-2']
      },
      OFFER_DETAIL: {
        ...DEFAULT_OPTIONS.OFFER_DETAIL,
        ALLOWED_ATTRIBUTES: [
          'short_description',
          'place_detail',
          'municipalities_short',
          'course_level',
          'child_care',
          'course_time',
          'course_content',
          'integration_goal',
          'course_topic',
          'industry',
          'modules',
          'workload',
          'german_level_starting',
          'work_assignment',
          'intensity',
          'duration_detail',
          'entry_time',
          'external_url_2',
          'phone',
          'contact_email',
          'registrations'
        ]
      }
    }
  },
  /**
   * IAZH site configuration.
   */
  iazh: {
    features: {
      enableFullTextSearch: true,
      enableDepartmentsFilter: true
    },
    options: DEFAULT_OPTIONS
  },
  /**
   * Default fallback site configuration.
   */
  default: {
    features: {
      enableFullTextSearch: true,
      enableDepartmentsFilter: true
    },
    options: DEFAULT_OPTIONS
  }
};
